import React, { Suspense, useRef, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import { useTexture, OrbitControls, Stars } from "@react-three/drei";
import BeegruContainer from "../components/layout-components/BeegruContainer";
import {
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Popover,
} from "@material-ui/core";
import { ExpandMore, Info } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  canvas: {
    height: "35vh",
    cursor: "move",
  },
  beegruRed: {
    color: "#CC0000",
    fontWeight: "bold",
    background: "-webkit-linear-gradient(#F74134, #EB1C61)",
    "-webkit-background-clip": "text",
    "-webkit-text-fillColor": "transparent",
    lineHeight: "4.5rem",
  },
  beegruRedBig: {
    color: "#CC0000",
    fontWeight: "bold",
    background: "-webkit-linear-gradient(#F74134, #EB1C61)",
    "-webkit-background-clip": "text",
    "-webkit-text-fillColor": "transparent",
    // lineHeight: "9rem",
  },
  beegruBlack: {
    color: "#000000",
    lineHeight: "2.25rem",
  },
  contentContainer: {
    padding: "3rem",
    margin: "3rem",
  },
  contentContainerMarginFree: {
    padding: "3rem",
  },
  contentContainerRed: {
    border: "solid 2px #FFFFFF",
    // "&:hover": { border: "solid 2px #CC0000" },
  },
  footerContainer: {
    height: "25rem",
  },
  texty: {
    lineHeight: "2.25rem",
  },
  textySmall: {
    lineHeight: "1.875rem",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  info: {
    color: "#000000",
    "&:hover": {
      color: "#CC0000",
    },
  },
}));

function Box(props) {
  const mesh = useRef();
  // const [map0, map90, map180, map270, mapTop, mapBottom] = useTexture([
  //   "/images/logo/logo-square.svg",
  //   "/images/logo/logo-square-90.svg",
  //   "/images/logo/logo-square-180.svg",
  //   "/images/logo/logo-square-270.svg",
  //   "/images/logo/logo-square-top.svg",
  //   "/images/logo/logo-square-bottom.svg",
  // ]);
  const [map0, map90, map180, map270, mapTop, mapBottom] = useTexture([
    "/images/logo/logo-white-border.svg",
    "/images/logo/logo-white-border-90.svg",
    "/images/logo/logo-white-border-180.svg",
    "/images/logo/logo-white-border-270.svg",
    "/images/logo/logo-white-border.svg",
    "/images/logo/logo-white-border.svg",
  ]);
  // const [hovered, setHover] = useState(props.hovered);
  const [active, setActive] = useState(false);
  const [scale, setScale] = useState(1);
  const [x, setX] = useState(135);
  const [y, setY] = useState(0);
  const [z, setZ] = useState(45);
  // const dummy = useMemo(() => new THREE.Object3D(), []);
  useFrame((state, delta) => {
    if (!props.hovered) {
      state.camera.position.x = 0;
      state.camera.position.y = 0;
      state.camera.position.z = 2;
      mesh.current.rotation.x = Math.floor(((x * Math.PI) / 180) * 100) / 100;
      setX(x + 1);
      if (x >= 360) setX(0);
      mesh.current.rotation.y = Math.floor(((y * Math.PI) / 180) * 100) / 100;
      setY(y + 1);
      if (y >= 360) setY(0);
      mesh.current.rotation.z = Math.floor(((z * Math.PI) / 180) * 100) / 100;
      setZ(z + 1);
      if (z >= 360) setZ(0);
      setScale(scale <= 1 ? 1 : scale - 0.005);
    } else {
      if (x !== 135) {
        mesh.current.rotation.x = Math.floor(((x * Math.PI) / 180) * 100) / 100;
        setX(x + 7.5);
        if (x >= 360) setX(0);
      }
      if (y !== 0) {
        mesh.current.rotation.y = Math.floor(((y * Math.PI) / 180) * 100) / 100;
        setY(y + 7.5);
        if (y >= 360) setY(0);
      }
      if (z !== 45) {
        mesh.current.rotation.z = Math.floor(((z * Math.PI) / 180) * 100) / 100;
        setZ(z + 7.5);
        if (z >= 360) setZ(0);
      }
      setScale(scale >= 1.2 ? 1.2 : scale + 0.005);
    }
  });
  return (
    <mesh
      {...props}
      ref={mesh}
      scale={scale}
      onClick={(event) => {
        setActive(!active);
      }}
      // onPointerOver={(event) => setHover(true)}
      // onPointerOut={(event) => setHover(false)}
      // rotation={[135 * (Math.PI / 180), 0, 45 * (Math.PI / 180)]}
    >
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      {/* <meshStandardMaterial color={hovered ? "0x00ff00" : "orange"} /> */}
      <meshStandardMaterial attachArray="material" map={map0} />
      <meshStandardMaterial attachArray="material" map={map90} />
      <meshStandardMaterial attachArray="material" map={mapBottom} />
      <meshStandardMaterial attachArray="material" map={map270} />
      <meshStandardMaterial attachArray="material" map={map0} />
      <meshStandardMaterial attachArray="material" map={map90} />
    </mesh>
  );
}

const Home = (props) => {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <BeegruContainer>
        <div className={classes.contentContainer} elevation={3}>
          <Grid
            display="flex"
            flexDirection="row"
            justify="space-between"
            alignItems="center"
            container
          >
            <Grid xs={12} sm={6} item>
              <Typography
                className={classes.beegruRedBig}
                variant="h2"
                component="h1"
              >
                The future of real estate is here.
              </Typography>
              <Typography className={classes.texty} variant="h5">
                The blockchain technology has already started being used in
                different parts of the world to keep track of real estate
                transactions, maintaining ledger of land records and
                facilitating fractional distribution or tokenization of physical
                assets. We are heading towards a future where records of all
                physical and digital assets, and their transactions, will be
                moving to a publicly accessible digital ledger. Many governments
                across the world understand this and are working on legislation
                and laws to facilitate this digital future built on the
                blockchain.
              </Typography>
            </Grid>
            <Grid xs={12} sm={6} item>
              <div className={classes.contentContainerRed} elevation={3}>
                <div
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  className={classes.info}
                  className={classes.canvas}
                  onMouseOver={(e) => {
                    handlePopoverOpen(e);
                    setHovered(true);
                  }}
                  onMouseLeave={(e) => {
                    handlePopoverClose(e);
                    setHovered(false);
                  }}
                >
                  <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography component="span">
                      Stop playing with the cube!
                    </Typography>
                  </Popover>
                  <Canvas
                    camera={{
                      fov: 75,
                      near: 0.1,
                      far: 1000,
                      position: [0, 0, 2],
                    }}
                  >
                    <OrbitControls />
                    {/* <Stars /> */}
                    <ambientLight intensity={0.5} />
                    <spotLight
                      position={[10, 10, 10]}
                      angle={200}
                      penumbra={1}
                    />
                    <pointLight position={[-10, -10, -10]} />
                    <Suspense fallback={null}>
                      <Box hovered={hovered} position={[0, 0, 0]} />
                    </Suspense>
                  </Canvas>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid
          display="flex"
          flexDirection="row"
          justify="space-between"
          alignItems="center"
          container
        >
          <Grid xs={12} sm={6} item>
            <div className={classes.contentContainer} elevation={3}>
              <img
                src="/images/illustrations/what.svg"
                alt="Unable to load image"
                width={512}
                height={512}
                loading="lazy"
                referrerPolicy="no-referrer"
                longdesc="https://coin.beegru.com"
              />
            </div>
          </Grid>
          <Grid xs={12} sm={6} item>
            <div className={classes.contentContainer} elevation={3}>
              <Typography className={classes.beegruRed} variant="h3">
                What is Beegru Coin?
              </Typography>
              <Typography className={classes.texty} variant="h5">
                Beegru coin is a digital blockchain token, which will enable
                users to get more benefits by using the Beegru real estate
                services ecosystem.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <div className={classes.contentContainer} elevation={3}>
          <Typography className={classes.beegruRed} variant="h3">
            Tokenomics
          </Typography>
          <img
            src="/images/tokenomics/tokenomics.svg"
            alt="Unable to load image"
            width={1280}
            height={720}
            loading="lazy"
            referrerPolicy="no-referrer"
            longdesc="https://coin.beegru.com"
          />
        </div>
        <div className={classes.contentContainer} elevation={3}>
          <Typography className={classes.beegruRed} variant="h3">
            Why Beegru Coin?
          </Typography>
          <Typography className={classes.texty} variant="h5">
            We want to enable billions of online users to have access to real
            estate and related services enabled by the blockchain. Beegru coin
            strives to be at the forefront of this real estate blockchain
            transformation and wants to provide its users a seamless experience
            in the real estate ecosystem. Beegru has been working relentlessly
            for several years to create a powerful on-ground network of people,
            highly experienced in real estate and real estate related services,
            who interface with all concerned parties on a day-to-day basis and
            close transactions in the most transparent and professional manner.
            This helps us receive ground-level information about micro-markets,
            real time requirements and local trends. We have built our
            organization by relentlessly catering to the varied needs of our
            customers and by considering the various market factors that affect
            the ever-changing real estate market. We intend to use our team of
            experts and our collective experience to facilitate the use of
            Beegru coin to serve the real estate services ecosystem.
          </Typography>
        </div>
        <Grid
          display="flex"
          flexDirection="row"
          justify="space-between"
          alignItems="center"
          container
        >
          <Grid xs={12} sm={6} item>
            <div className={classes.contentContainer} elevation={3}>
              <Typography className={classes.beegruRed} variant="h3">
                How do I buy Beegru Coin?
              </Typography>
              <Typography className={classes.texty} variant="h5">
                The blockchain technology has already started being used in
                different parts of the world to keep track of real estate
                transactions, maintaining ledger of land records and
                facilitating fractional distribution or tokenization of physical
                assets. We are heading towards a future where records of all
                physical and digital assets, and their transactions, will be
                moving to a publicly accessible digital ledger. Many governments
                across the world understand this and are working on legislation
                and laws to facilitate this digital future built on the
                blockchain.
              </Typography>
            </div>
          </Grid>
          <Grid xs={12} sm={6} item>
            <div className={classes.contentContainer} elevation={3}>
              <img
                src="/images/illustrations/how.svg"
                alt="Unable to load image"
                width={512}
                height={512}
                loading="lazy"
                referrerPolicy="no-referrer"
                longdesc="https://coin.beegru.com"
              />
            </div>
          </Grid>
        </Grid>
        <div className={classes.contentContainer} elevation={3}>
          <Typography className={classes.beegruRed} variant="h3">
            FAQs
          </Typography>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography className={classes.beegruBlack} variant="h5">
                What is cryptocurrency?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.textySmall} variant="h6">
                It's a decentralized digital currency that uses encryption
                techniques to regulate the generation of currency units and
                verify funds transfer. Anonymity, decentralization, and security
                are among its main features. Cryptocurrency is not regulated or
                tracked by any centralized authority, government, or bank.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography className={classes.beegruBlack} variant="h5">
                Is cryptocurrency legal in India?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.textySmall} variant="h6">
                YES, cryptocurrency is legal in India but an illegal tender of
                money. Indian Banks handle cryptocurrency transactions from
                exchanges and traders.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography className={classes.beegruBlack} variant="h5">
                What is blockchain?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.textySmall} variant="h6">
                Information is stored in data blocks across a vast network of
                computers. Each phase in a transaction is a block. Each new
                block is added to the previous one to form a chain. Users cannot
                alter the data recorded in blocks without the alteration of all
                subsequent blocks. The information is chained and adhere to a
                protocol for inter-node communication and validating new blocks,
                hence the name.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </BeegruContainer>
      <Paper className={classes.footerContainer} elevation={3}>
        <BeegruContainer center>
          <img
            src="/images/logo/logo.svg"
            alt="Unable to load image"
            width={256}
            height={144}
            loading="lazy"
            referrerPolicy="no-referrer"
            longdesc="https://coin.beegru.com"
          />
          <Typography className={classes.texty} variant="h5">
            1090i, 18th Cross Road,
            <br />
            3rd Sector, HSR Layout,
            <br />
            Bengaluru, Karnataka 560 102
          </Typography>
          <br />
          <Typography className={classes.texty} variant="h5">
            1800 572 0999
          </Typography>
          <Typography className={classes.texty} variant="h5">
            +91 99807 75599
          </Typography>
          <Typography className={classes.texty} variant="h5">
            mail@beegru.com
          </Typography>
        </BeegruContainer>
      </Paper>
    </React.Fragment>
  );
};

export default Home;
